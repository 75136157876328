<template>
	<div class="p-grid">

    <div class="p-col-12 p-md-6">
      <div class="card p-fluid">
        <h5>Configuration du serveur</h5>
        <div class="p-field">
          <label for="server">Serveur</label>
          <InputText v-model="server" id="server" type="text" />
        </div>
        <div class="p-field">
          <label for="identifiant">identifiant</label>
          <InputText v-model="login" id="identifiant" type="text" />
        </div>
        <div class="p-field">
          <label for="mp">Mot passe</label>
          <InputText v-model="pwd" id="mp" type="password" />
        </div>
        <div class="p-field">
          <label for="version">Version API</label>
          <InputNumber   v-model="version" id="version" showButtons mode="decimal"></InputNumber>
        </div>
        <div class="p-field">
          <label for="nb_camera">Version camera</label>
          <InputNumber   v-model="nb_cam" id="nb_camera" showButtons mode="decimal"></InputNumber>
        </div>
        <div class="p-field">
          <label for="ext_record">Version  external recording</label>
          <InputNumber   v-model="ext_record" id="ext_record" showButtons mode="decimal"></InputNumber>
        </div>


        <Button @click="save" :loading="loading" label="Enregistrer" class="p-mr-2 p-mb-2"></Button>


        <Button @click="open"   label="Test SSL" class="p-button-secondary p-text-center p-mt-5"></Button>



      </div>

    </div>
	</div>
</template>

<script>
export default {

  data() {
    return {
      nb_cam: 0,
      version: 0,
      ext_record: 0,
      pwd: '',
      login: '',
      server: '',
      loading: false,
    }
  },
  mounted() {
    if (localStorage.nb_cam) { this.nb_cam = parseInt(localStorage.nb_cam); }
    if (localStorage.pwd) { this.pwd = localStorage.pwd; }
    if (localStorage.login) { this.login = localStorage.login; }
    if (localStorage.server) { this.server = localStorage.server; }
    if (localStorage.version) { this.version = parseInt(localStorage.version); }
    if (localStorage.ext_record) { this.ext_record = parseInt(localStorage.ext_record); }
  },
  methods: {
    open(){
      window.open(localStorage.server, '_blank');
    },
    save() {
      this.loading = true
      localStorage.nb_cam = parseInt(this.nb_cam);
      localStorage.pwd = this.pwd;
      localStorage.login = this.login;
      localStorage.server = this.server;
      localStorage.version = parseInt(this.version);
      localStorage.ext_record = parseInt(this.ext_record);
      setTimeout(() => this.loading = false, 1000);
    }
  }
}
</script>

<style scoped>

</style>
